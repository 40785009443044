export const blogContent1 = [
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent2 = [
    {
        image: require("../../images/our-services/ship/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
]


export const blogContent3 = [
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]


export const blogContent4 = [
    {
        image: require("../../images/our-work/leather/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
]

export const blogContent5 = [
    {
        image: require("../../images/our-work/nuclear/pic1.jpg"),
        title:"Why You Should Not Go To Industry",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/nuclear/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/nuclear/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/nuclear/pic4.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
]

export const blogContent6 = [
    {
        image: require("../../images/our-work/beer/pic1.jpg"),
        title:"Why You Should Not Go To Industry",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/beer/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/beer/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/beer/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent7 = [
    {
        image: require("../../images/our-work/car/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent8 = [
    {
        image: require("../../images/our-work/mining/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent9 = [
    {
        image: require("../../images/our-work/plastic/pic1.jpg"),
        title:"Why You Should Not Go To Industry",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/plastic/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/plastic/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/plastic/pic4.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
]

export const ExibitionContent1 = [
    {
        image: require('./../../images/gallery/food/pic1.jpg'),
    },
    {
        image: require('./../../images/gallery/food/pic2.jpg'),
    },
    {
        image: require('./../../images/gallery/food/pic3.jpg'),
    },
    {
        image: require('./../../images/gallery/food/pic4.jpg'),
    },
]

export const exibitionContent2 = [
    {
        image: require('./../../images/gallery/leather/pic1.jpg'),
    },
    {
        image: require('./../../images/gallery/leather/pic2.jpg'),
    },
    {
        image: require('./../../images/gallery/leather/pic3.jpg'),
    },
    {
        image: require('./../../images/gallery/leather/pic4.jpg'),
    },
]


export const projectContent1 = [
    {
        image: require('../../images/our-work/steelplant/pic10.jpg'),
        projectName: 'Manufacturing',
    },
    {
        image: require('../../images/our-work/steelplant/pic9.jpg'),
        projectName: 'Iron Making',
    },
    {
        image: require('../../images/our-work/steelplant/pic8.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/steelplant/pic7.jpg'),
        projectName: 'Structural Steel',
    },
    {
        image: require('../../images/our-work/steelplant/pic10.jpg'),
        projectName: 'Manufacturing',
    },

]

export const projectContent2 = [
    {
        image: require('../../images/our-work/solarplant/pic1.jpg'),
        projectName: 'Manufacturing',
    },
    {
        image: require('../../images/our-work/solarplant/pic2.jpg'),
        projectName: 'Iron Making',
    },
    {
        image: require('../../images/our-work/solarplant/pic3.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/solarplant/pic4.jpg'),
        projectName: 'Structural Steel',
    },
	{
        image: require('../../images/our-work/solarplant/pic3.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/solarplant/pic4.jpg'),
        projectName: 'Structural Steel',
    },

]

export const servicesContent1 = [
    {
        serviceName:"Wind Turbines",
        icon:"flaticon-worker"
    },
    {
        serviceName:"Solar Panels",
        icon:"flaticon-factory"
    },
    {
        serviceName:"Maintenance",
        icon:"flaticon-settings"
    },
    {
        serviceName:"Maintenance",
        icon:"flaticon-engineer-1"
    }
]

export const serviceContent2 = [
    {
        serviceName:"Material Engineering",
        icon:"flaticon-worker"
    },
    {
        serviceName:"Power and Energy",
        icon:"flaticon-factory"
    },
    {
        serviceName:"Agricultural Engineering",
        icon:"flaticon-settings"
    },
    {
        serviceName:"Petroleum Engineering",
        icon:"flaticon-engineer-1"
    },
    {
        serviceName:"Material Engineering",
        icon:"flaticon-worker"
    },
]

export const servicesContent3 = [
    {
        image: require("../../images/our-work/solarplant/pic1.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Wind Turbines",
    },
    {
        image: require("../../images/our-work/solarplant/pic2.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Solar Panels",
    },
    {
        image: require("../../images/our-work/solarplant/pic3.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Maintenance",
    },
    {
        image: require("../../images/our-work/solarplant/pic2.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Solar Panels",
    },
    {
        image: require("../../images/our-work/solarplant/pic3.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Maintenance",
    }
]

export const servicesContent4 = [
    {
        image: require("../../images/our-services/pic1.jpg"),
        description:"Materials of construction available include PVC & CPVC Schedule 80, Polypropylene, Polyethylene, PVDF and FRP. Our pumps, valves, rotameters, sensors & control instrumentation, and filtration products are used extensively in the waste treatment and Energy industry for applications involving corrosive and clean chemicals.",
        serviceName:"Chemical Piping Systems",
    },
    {
        image: require("../../images/our-services/pic2.jpg"),
        description:"We are ready to serve you for all your electrical contracting works, from foundation grounding to environmental lighting, LV panel supply and assembly, HV transformer installation and power increase. Energy Saving Analysis and Measurements. Productivity Enhancing Projects",
        serviceName:"Electrical Equipment Automation(E&I) Control System Supply and Maintenance service",
    },
    {
        image: require("../../images/our-services/pic3.jpg"),
        description:"Piping systems ,PH ORP DO TSS Conductivity & all process measurement , Control Systems, Filtration & RO treatment Skids",
        serviceName:"Water, Wastewater Treatment Plant design, execution & Equipment Supply",
    },
    {
        image: require("../../images/our-services/pic4.jpg"),
        description:"Panther Energy delivers new gas & steam turbine units, genuine gas & steam turbine parts and quality gas & steam turbine maintenance services to its esteemed clients. As power generation from renewables increases, conventional power plants are expected to show greater flexibility while the need for energy storage grows. Whether consideri",
        serviceName:"Power",
    },
    {
        image: require("../../images/our-services/pic5.jpg"),
        description:"For several process industries including the Food and Beverage industry. As such, we provide high-quality valves for process control, accurate measurement solutions for liquid, solid and gas measurement, process solutions such as pumps and compressors to convey process fluids, electrical and instrumentation solutions to energize and monito",
        serviceName:"Pharmaceutical, Food And Beverage",
    },
    {
        image: require("../../images/our-services/pic6.jpg"),
        description:"Oil & Gas production, transportation, and storage operations are subject to the highest and most complex process conditions and are subject to strict standards of performance, availability, and efficiency. The requirements for flow measurement in the oil and gas industry should be just as stringent! ",
        serviceName:"Oil & Gas",
    },
]

export const homeSliderContent2 = [
    {
        image: require("../../images/main-slider/slide4.jpg"),
        title:"INDUSTRIAL EQUIPMENT SUPPLIER",
        description:"Years of working within Energy industry has given us the skills to supply key projects.",
    },
    {
        image: require("../../images/main-slider/slide5.jpg"),
        title:"GAS INDUSTRY",
        description:"We believe in representing High Quality products and manufacturers who have similar values as we do.  We will continue to assist the Natural Gas Industry with Quality, Integrity and Safety in mind."
    }
]

export const homeSliderContent3 = [
    {
        image: require("../../images/main-slider/slide21.jpg"),
        title:"Economy Needs A Healthy Steel Industry",
    },
    {
        image: require("../../images/main-slider/slide22.jpg"),
        title:"Economy Needs A Healthy Steel Industry",
    }
]

export const homeSliderContent4 = [
    {
        image: require("../../images/main-slider/slide1.jpg"),
        title:"Fast and Reliable Electrical services",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide2.jpg"),
        title:"Fast and Reliable Electrical services",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent5 = [
    {
        image: require("../../images/main-slider/slide9.jpg"),
        title:"WE BUILD YOUR DREAM",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide10.jpg"),
        title:"WE ARE CONSTRUCT",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]


export const homeSliderContent6 = [
    {
        image: require("../../images/main-slider/slide11.jpg"),
        title:"Solar Wind Power Technology ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide12.jpg"),
        title:"Solar Wind Power Technology ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent7 = [
    {
        subTitle:"Our Latest Item Food Industry",
        image: require("../../images/main-slider/slide13.jpg"),
        title:"Our Latest Item Food Industry ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        subTitle:"Our Latest Item Food Industry",
        image: require("../../images/main-slider/slide14.jpg"),
        title:"Our Latest Item Food Industry ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent8 = [
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide25.jpg"),
        title:"Welcome Leather Industry",
    },
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide24.jpg"),
        title:"Welcome Leather Industry",
    }
]

export const homeSliderContent9 = [
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide23.jpg"),
        title:"Nuclear Power Plants",
        description:"Great Lessons You Can Learn From Nuclear Plant"
    }
]

export const homeSliderContent10 = [
    {
        subTitle2:"Sale Car – Buy Car – Car Service",
        image: require("../../images/main-slider/slide29.jpg"),
        title:"Auto Mobile Industry",
        description:" With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play."
    }
]

export const homeSliderContent11 = [
    {
        subTitle:"Value Creation Form Waste ",
        image: require("../../images/main-slider/slide19.jpg"),
        title:"Plastic Waste Managment & Recycling",
        description:"Powering Progress Through Plastic"
    }
]